import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Archive from '../../assets/images/icons/archive.svg';
import Brain from '../../assets/images/icons/brain-thought.svg';
import Eye from '../../assets/images/icons/eye.svg';
import Touch from '../../assets/images/icons/touch.svg';
import CardSecure from '../../assets/images/icons/card-secure.svg';
import Shield from '../../assets/images/icons/shield.svg';

const ProofSystems = () => {
  return (
    <PageLayout>
      <SEO
        title="Chain of custody redefined with Proof Systems"
        description="Blockchain based solutions provide new Proof Systems that restore consumer trust issues and resolve legal liabilities."
      />
      <Layout dark background="blue">
        <Hero type="code">
          <div className="caption">Proof systems</div>
          <Title color="white">Chain of custody redefined with Proof Systems</Title>
          <Description color="blue-light">
            Proof Systems ready to adapt to every custom implementation. Increase credibility with provable
            integrity, restoring trust issues consumers may have in processes, products, and organizations.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Archive,
                title: 'Trust',
                body:
                  'Blockchain based chain of custody solutions provide new Proof Systems that resolve issues with consumer trust.',
              },
              {
                Icon: Eye,
                title: 'Assurance',
                body:
                  'Outcomes of events can occur with certainty and confidence through automation, and then be independently proven.',
              },
              {
                Icon: Touch,
                title: 'Credibility',
                body:
                  'Inauthentic products, false claims, low-quality materials, or affiliation with child labour are all credibility issues for brands.',
              },
              {
                Icon: CardSecure,
                title: 'Integrity',
                body:
                  'Companies are held accountable by consumers. Goods and services must provably be provided in a humane and sustainable way.',
              },
              {
                Icon: Shield,
                title: 'Origin and Traceability',
                body: 'Provide proof reports to authorized parties or consumers every step of the way.',
              },
              {
                Icon: Brain,
                title: 'Reputation risks',
                body:
                  'Making commitments does not improve integrity. Proof commitments to consumers once achieved, and prevent reputation risks.',
              },
            ]}
          />
          <Row gap="small">
            <Column span={12}>
              <Section subtitle="Proof Systems" title="The internet of trust" caption="Increase credibility">
                At its core, all blockchain based chain of custody solutions on Dragonchain can be seen as
                proof systems. Traditional chain of custody solutions are difficult to trust. It may seem that
                evidence was handled and archived chronologically without tampering, but there was no way to
                prove this. With Dragonchain's proof systems, a company is able to offer full integrity
                throughout the entire chain of custody. The first thing that comes to mind when thinking about
                chain of custody, is forensic evidence of a crime, such as a drug test. Proof Systems go
                beyond this, replacing any and all paper trails with a digital trail. Protected by math,
                hashpower, and so much money to tamper it that nobody can - or wants to - afford it. The
                global supply chain is already innovating with Proof Systems. They provide more traceability
                to consumers by transparently showing the full origin of products throughout the entire supply
                chain. This also protects brands from losing credibility.
              </Section>
            </Column>
          </Row>
          <Row gap="large" auto span={12}>
            <Column>
              <Section>
                <ul>
                  <li>
                    Proof how much wind or solar energy was produced, how this energy was distributed, and
                    that profits were shared according to the contract terms.
                  </li>
                  <li>
                    Embolden risk mitigation with timestamped and watermarked documents for contract
                    transparency with on chain proof.
                  </li>
                  <li>
                    Proof water quality was tested in a lab or through sensors, and what the results were at
                    any given day or time. Automate warnings in cases of anomalies.
                  </li>
                  <li>
                    Proof the origin of ingredients and resources, throughout the entire supply chain, from
                    producers, to distributers, to end consumers.
                  </li>
                  <li>
                    Proof there was no voter fraud during elections, and that all voter registrations, voter
                    processes, and final votes were legal and binding.
                  </li>
                  <li>
                    Proof gaming micro-transactions and change of ownership of digital (tokenized) items.{' '}
                  </li>
                  <li>
                    Proof you reduced the use of plastics for packaging, or lowered the amount of (artificial)
                    sugar and salt in products.{' '}
                  </li>
                </ul>
              </Section>
            </Column>
          </Row>
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Are Proof Systems affordable to integrate?">
                  Proof System integrations are affordable.
                </Accordion.Item>
                <Accordion.Item title="Will sensitive data or business logic kept secure?">
                  Always. Dragonchain's Proof Systems are hybrid, and sensitive information is never exposed
                  publicly unless explicitly permissioned otherwise. For some use cases a company might want
                  to, or need to, expose pieces of information on public networks. While other use cases can
                  remain private or only shared within a consortium to authorized parties. Even without
                  exposing information publicly, at a later point in time there is proof that a certain event
                  happened at a specific day and time.
                </Accordion.Item>
                <Accordion.Item title="What are some other areas where Proof Systems can provide value?">
                  According to the survey 'Proof Systems in Blockchain', general applications and use cases
                  can also be found in escrow, bonded contracts, arbitration, signatures. Transactions use
                  cases and applications in terms of fintech and payments can be found in stock, private
                  equity, crowdfunding, bonds, mutual funds, derivatives, annuities, pensions. Public records
                  examples include, but are not limited to property titles, vehicle registrations, business
                  licenses, marriage certiﬁcates, death certiﬁcates. Identiﬁcation use cases with our
                  decentralized identity technology solutions can apply to driver’s licenses, identity cards,
                  passports, voter registrations. Private records such as IOUs, loans, contracts, bets,
                  signatures, wills, trusts, escrows can all be proven in a blockchain based chain of custody
                  integration. Attestation examples are proof of insurance, proof of ownership, notarized
                  documents. Physical asset events include owning, visiting, and/or using homes and
                  apartments, hotel rooms, rental cars, automobile access. Intangible assets are patents,
                  trademarks, copyrights, reservations, domain names.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Want to learn more?">
                Integrate Proof Systems into your existing solutions, or design and build your custom Proof
                System fully adapted to your business needs. Schedule a call with the creators to find out how
                blockchain based chain of custody technology can be applied.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default ProofSystems;
